

















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Mutation } from 'vuex-class'
import ManageTable from '@/components/shared/ManageTable.vue'
import { applyTransferRules, applyTransferOptions } from '@/data/ApplyTransferFormData'
import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import BaseCurrencyInfo from '@/components/remittance/BaseCurrencyInfo.vue'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import { Utils } from '@/static/Utils'
import ManageTableOptions from '@/models/forms/ManageTableOptions'

@Component({
  components: { BasicDialog, BaseCurrencyInfo, ManageTable }
})
export default class Register extends Vue {
  @Getter getValidatedTransfers: any
  @Mutation setRegisterTransfers: any
  @Mutation disableLoading: any

  countryWithMethods: Array<string> = []
  selectedCountryWithMethod: string = ''
  transfers: Dictionary<Array<TransferRow>> = {}
  pageTransfers: Dictionary<Dictionary<Array<TransferRow>>> = {}
  tableOption: Dictionary<ManageTableOptions> = applyTransferOptions
  descriptor: Dictionary<any> = applyTransferRules
  totalCount: Dictionary<number> = {}
  page: number = 1
  unit: number = 20
  baseCurrencyInfoDialog = false

  get hasUpdatingTransfers (): boolean {
    let hasUpdating = false
    this.countryWithMethods.forEach(countryWithMethod => {
      const isUpdating: boolean = this.transfers[countryWithMethod].some(recipient => {
        return !!recipient.updatable
      })
      hasUpdating = hasUpdating || isUpdating
    })
    return hasUpdating
  }

  getCountryMethodLabel (countryWithMethod: string): string {
    const countryLabel = Utils.getCountryMethodLabel(countryWithMethod)
    const totalCount = this.totalCount[countryWithMethod]
    const totalCountLabel = this.$t('commons.total_case', { total_case: totalCount })
    return `${countryLabel} (${totalCountLabel})`
  }

  handleTabClick (): void {
    this.page = 1
  }

  openInfoModal () {
    this.baseCurrencyInfoDialog = true
  }

  deleteRow (countryWithMethod: string, index: number): void {
    const pageTransfer = this.pageTransfers[countryWithMethod][this.page][index]
    this.pageTransfers[countryWithMethod][this.page].splice(index, 1)
    const transferIndex = this.transfers[countryWithMethod].findIndex(transfer => transfer.id === pageTransfer.id)
    this.transfers[countryWithMethod].splice(transferIndex, 1)
    this.setPaginatedTransfers()
    this.totalCount[countryWithMethod]--
  }

  toggleUpdatable (countryWithMethod: string, index: number, callback: any): void {
    const transferRow = this.pageTransfers[countryWithMethod][this.page][index]
    transferRow.updatable = transferRow.updatable === true ? !transferRow.updatable : true
    if (callback) callback()
  }

  apply (): void {
    if (!this.hasUpdatingTransfers) {
      const registerTransfer: Array<TransferRow> = []
      this.countryWithMethods.forEach(countryWithMethod => {
        const pageTransfers: Dictionary<Array<TransferRow>> = this.pageTransfers[countryWithMethod]
        Object.keys(pageTransfers).forEach(page => {
          const transfer: Array<TransferRow> = pageTransfers[page]
          registerTransfer.push(...transfer)
        })
      })
      this.setRegisterTransfers(registerTransfer)
      this.$router.push('/transfer/register')
    }
  }

  setTransfer (): void {
    this.countryWithMethods = Object.keys(this.getValidatedTransfers)
    this.transfers = Object.assign({}, this.getValidatedTransfers)
    this.selectedCountryWithMethod = this.countryWithMethods[0]
  }

  setPaginatedTransfers (): void {
    this.countryWithMethods.forEach(countryWithMethod => {
      let page = 1
      const transfers: Array<TransferRow> = Array(...this.transfers[countryWithMethod])
      while (transfers.length > 0) {
        if (!this.pageTransfers[countryWithMethod]) this.pageTransfers[countryWithMethod] = {}
        this.pageTransfers[countryWithMethod][page] = transfers.splice(0, this.unit)
        page++
      }
      this.totalCount[countryWithMethod] = this.transfers[countryWithMethod].length
    })
  }

  created () {
    this.setTransfer()
    this.setPaginatedTransfers()
    this.disableLoading()
  }
}
